import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { browserHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";
import uuid from "uuid/v1";
import "autotrack/lib/plugins/url-change-tracker";

import configureStore from "../page/store/configureStore";

import Root from "../page/containers/Root";

// Make sure buyer_details field is always in last position of fields array when present
const buyerDetailsIndex = window.page.fields.findIndex(
  (field) => field.name === "buyer_details"
);
const sortedFields =
  buyerDetailsIndex > -1
    ? [
        ...window.page.fields.slice(0, buyerDetailsIndex),
        ...window.page.fields.slice(buyerDetailsIndex + 1),
        window.page.fields[buyerDetailsIndex]
      ]
    : window.page.fields;

const store = configureStore({
  agent: window.agent,
  page: window.page,
  form: { fields: sortedFields },
  sessionId: uuid()
});

Object.assign(window, {
  page: null,
  agent: null
});

const history = syncHistoryWithStore(browserHistory, store);

render(
  <Root store={store} history={history} />,
  document.getElementById("root")
);
